/**
 * Base class which takes the options
 */

class Pico {
    /**
     * @param {Event} e
     * @param {Object} config
     * @param {Window} context
     */
    constructor(e, config = {}, context) {
        this.scriptLoaded = false
        this.publisherId = config.publisherId
        this.windowContext = context
        this.picoInit = config.picoInit
        this.localStorageSupported = this.localStorageAvailable() || false
        this.debugMode = this.localStorageSupported ? context.localStorage.getItem('DEBUG') === '*' : false

        // don't allow the class to refer to more than one instance
        if (Pico.instance) {
            if (this.debugMode) {
                /* eslint-disable-next-line no-console */
                console.log('Instance is already instantiated, returning existing instance')
            }
            return Pico.instance
        }

        if (config && typeof config === 'object') {
            this.config = { ...config }
        } else {
            throw new TypeError('You must pass an object')
        }

        Pico.instance = this

        /* eslint-disable no-prototype-builtins */
        if (!this.windowContext.Pico.hasOwnProperty('publisher_id')) {
            // eslint-disable-next-line camelcase
            this.windowContext.Pico.publisher_id = this.publisherId
            this.windowContext.document.body.setAttribute('data-pico-id', this.publisherId)
        }

        if (this.debugMode) {
            /* eslint-disable-next-line no-console */
            console.log('Dispatching event from wrapper constructor function')
        }

        this.windowContext.document.dispatchEvent(this.picoInit)
    }

    localStorageAvailable() {
        let storage
        try {
            storage = this.windowContext.localStorage
            const x = '__storage_test__'
            storage.setItem(x, x)
            storage.removeItem(x)
            return true
        } catch (e) {
            /* eslint-disable-next-line no-console */
            console.error('Local storage not available')
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22
                // Firefox
                || e.code === 1014
                // test name field too, because code might not be present
                // everything except Firefox
                || e.name === 'QuotaExceededError'
                // Firefox
                || e.name === 'NS_ERROR_DOM_QUOTA_REACHED'
            )
            // acknowledge QuotaExceededError only if there's something already stored
            && storage.length !== 0
        }
    }

    loadScript(payload) {
        const s = this.windowContext.document.createElement('script')
        s.type = 'text/javascript'
        // Setting crossorigin=anonymous forces chrome to send an Origin header with the request
        s.setAttribute('crossorigin', 'anonymous')
        s.src = process.env.WRAPPER_DOMAIN || 'https://gadget.dev.pico.tools/load/build.js' // we'll need to env this at some point and use the protocol of the publisher
        s.onload = this.init(payload)
        this.windowContext.document.body.appendChild(s)
        if (this.debugMode) {
            /* eslint-disable-next-line no-console */
            console.log('Loading bundle', s.src)
        }
    }

    init() {
        this.scriptLoaded = true
        if (this.windowContext.Pico.handleNavigationEvent) {
            if (this.debugMode) {
                /* eslint-disable-next-line no-console */
                console.log('Pico handler declared, calling...')
            }
            this.windowContext.Pico.handleNavigationEvent(this.windowContext.Pico.post)
        } else {
            if (this.debugMode) {
                /* eslint-disable-next-line no-console */
                console.log('Pico handler not declared, waiting...')
            }
            this.windowContext.addEventListener('pico.ready', () => {
                if (this.debugMode) {
                    /* eslint-disable-next-line no-console */
                    console.log('Pico init event fired, loading...')
                }
                this.windowContext.Pico.handleNavigationEvent(this.windowContext.Pico.post)
            })
        }
    }

    handleQueueItems(queueItems = []) {
        if (this.debugMode) {
            /* eslint-disable-next-line no-console */
            console.log('handleQueueItems called', queueItems)
        }

        queueItems.forEach((queueItem) => {
            const [command, args] = queueItem
            switch (command) {
                case 'visit':
                    this.visit(args)
                    break
                default:
                    break
            }
        })
    }

    /**
     * @param {Object} payload
     */
    visit(payload) {
        this.setPostData(payload)
        if (this.scriptLoaded) {
            this.windowContext.Pico.handleNavigationEvent(this.windowContext.Pico.post)
        } else {
            if (this.debugMode) {
                /* eslint-disable-next-line no-console */
                console.log('Script has not been loaded yet')
            }
            this.loadScript(payload)
        }
        if (!this.windowContext.Pico.hasOwnProperty('post')) {
            if (this.debugMode) {
                /* eslint-disable-next-line no-console */
                console.log('Window does not have post info already', payload)
            }
        } else if (this.debugMode) {
            /* eslint-disable-next-line no-console */
            console.log('Window has post info already', payload)
        }
    }

    setPostData(payload) {
        const {
            article,
            author,
            title,
            // eslint-disable-next-line camelcase
            break_selector,
            // eslint-disable-next-line camelcase
            post_id,
            // eslint-disable-next-line camelcase
            resource_ref,
            taxonomies,
            url,
            // eslint-disable-next-line camelcase
            post_type = null,
        } = payload

        this.windowContext.Pico.post = {
            // eslint-disable-next-line camelcase
            content_chop: article,
            // eslint-disable-next-line camelcase
            post_id,
            author,
            title,
            // eslint-disable-next-line camelcase
            post_type,
            // eslint-disable-next-line camelcase
            resource_ref,
            taxonomies,
            url,
        }

        // eslint-disable-next-line camelcase
        this.windowContext.Pico.break_selector = break_selector || null
    }
}

export default {
    instance: null,
    getInstance(event, config, context) {
        if (!this.instance) {
            this.instance = new Pico(event, config, context)
        }
        return this.instance
    },
    handleEvent(queuedItem) {
        if (this.instance && queuedItem) {
            this.instance.handleQueueItems([queuedItem])
        }
    },
    processQueueItems(queue) {
        if (this.instance && queue) {
            this.instance.handleQueueItems(queue)
        }
    },
}
